import React, { useCallback } from 'react';

export default function ExternalLink({ children, href, ...rest }) {
  const linkRef = useCallback(
    (node) => {
      if (node !== null) {
        window.analytics.trackLink(node, 'ExternalLink Clicked', {
          href,
        });
      }
    },
    [href],
  );

  return (
    <a ref={linkRef} href={href} {...rest}>
      {children}
    </a>
  );
}
